import React from "react"
import Container from "../components/container"
import SEO from "../components/seo"
import About from "../AboutUs"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Container defKey="4">
    <SEO
      title="About Us"
      description="Virtual Start has extensive experience in creating the best 3d virtual tours"
    />
    <About />
    <Helmet>
      <script type="text/javascript">
        {`var Tawk_API = Tawk_API || {}, Tawk_LoadStart = new Date();
            (function () {var s1 = document.createElement("script");
            s0 = document.getElementsByTagName("script")[0];
            s1.async = true;
            s1.src = "https://embed.tawk.to/5fdfebf9a8a254155ab50cf7/1eq1b3i27";
            s1.charset = "UTF-8";
            s1.setAttribute("crossorigin", "*");
            s0.parentNode.insertBefore(s1, s0);
          })();`}
      </script>
    </Helmet>
  </Container>
)

export default NotFoundPage
