import designIcon from  '../Home/Assets/design2.png'
import devicesIcon from '../Home/Assets/devices (1).png';
import ukIcon from '../Home/Assets/uk.png';
import supportIcon from '../Home/Assets/customerSupport.png';
import marketingIcon from '../Home/Assets/marketing2.png';
import photographyIcon from '../Home/Assets/photography2.png';

export const Feature30DataSource = {
  wrapper: { className: 'home-page-wrapper content3-wrapper' },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: 'About US',
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: '100% CUSTOMER SATISFICATION',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children: designIcon,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Bespoke design' },
          content: {
            className: 'content3-content',
            children:
              'Make a virtual tour trully yours by adding a custom made interface and lots of functionality',
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              devicesIcon,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Optimised for all devices' },
          content: {
            className: 'content3-content',
            children:
              'Built based on the latest HTML5 technology, our virtual tours work seamlessly across all devices',
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              ukIcon,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Cover all UK' },
          content: {
            className: 'content3-content',
            children:
              'Our services stretch all over the UK. Strategically based in London, no place is too far',
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              supportIcon,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Customer support' },
          content: {
            className: 'content3-content',
            children:
              'Our customer support, rated by our clients as excellent, will assist any query within the next 24 hours',
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              marketingIcon,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Marketing Expertise' },
          content: {
            className: 'content3-content',
            children:
              'Our team consist of marketing specialist can help to optimise your online marketing strategy with 360° virtual tour',
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              photographyIcon,
          },
          textWrapper: { className: 'content3-text' },
          title: { className: 'content3-title', children: 'Professional photography' },
          content: {
            className: 'content3-content',
            children:
              'Our goal is to create high quality photographs that will exceed your expectations',
          },
        },
      },
    ],
  },
};
